import { fetcher } from '~/libs/fetcher';

type TermsResponse = {
  slug: string;
  id: number;
  acf: {
    company_top_image: number;
  };
};
export const useCompanyTerm = () => {
  const { data } = useAuth();
  const terms = useState<TermsResponse[]>('currentTerm', () => []);

  const getCurrentTermId = async () => {
    const resolvedTerms = await _resolveTerms();

    const term = resolvedTerms.find(
      (item) => item.slug === data.value?.acf['user_company_slug'],
    )?.id;
    if (term == null) throw new Error('企業タームが見つかりません');
    return term;
  };

  const getCompanyLogoImage = async () => {
    const resolvedTerms = await _resolveTerms();

    const imageId = resolvedTerms.find(
      (item) => item.slug === data.value?.acf['user_company_slug'],
    )?.acf.company_top_image;

    if (imageId == null)
      throw new Error(
        `企業ロゴ画像が見つかりません 企業スラッグ:${data.value?.acf['user_company_slug']}`,
      );

    return _getMediaUrl(imageId);
  };

  const _resolveTerms = async () => {
    if (terms.value.length) return terms.value;

    terms.value = await _getTermsRecursively();
    return terms.value;
  };

  const _getTermsRecursively = async () => {
    let page = 1;
    const { data, total } = await fetcher<TermsResponse[]>(
      `/wp/v2/company?per_page=100&page=${page}`,
    );
    const terms = data;

    while (Number(total) > terms.length) {
      page++;
      const { data } = await fetcher<TermsResponse[]>(
        `/wp/v2/company?per_page=100&page=${page}`,
      );
      terms.push(...data);
    }

    return terms;
  };

  const _getMediaUrl = async (mediaId: number) => {
    const { data: media } = await fetcher<{ source_url: string }>(
      `/wp/v2/media/${mediaId}`,
    );
    return media.source_url;
  };

  return {
    getCurrentTermId,
    getCompanyLogoImage,
    _resolveTerms,
  };
};
